<template>
    <div>
        <div class="header">
            <ds-header style="width: 100%"></ds-header>
            <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
        </div>
        <a-form class="formModel" layout="horizontal" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <a-form-item label="所属项目" v-if="!$route.query.id" required>
                <a-cascader :disabled="!!$route.query.id" expandTrigger="hover" style="width: 800px;"
                    v-model="add.projectId" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    :options="projectName" :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }"
                    placeholder="请选择所属项目" />
            </a-form-item>
            <a-form-item v-if="!!$route.query.id" label="所属项目" required>
                <a-input :disabled="!!$route.query.id" v-model="add.projectName" style="width: 800px;" />
            </a-form-item>
            <a-form-model-item style="z-index: 0;" required label="展示文案">
                <div style="color: red;">若用于h5快捷拨打电话，联系方式须在{}标签中，例：{1522****333}</div>
                <div class="content" style="width: 800px;">
                    <div id="editer"></div>
                </div>
            </a-form-model-item>
            <a-form-model-item  :wrapper-col="{ span: 6, offset: 8 }">
                <a-button type="primary" @click="handleAddOk" style="margin-right: 20px"> 确定 </a-button>
                <a-button type="info" @click="goBack"> 取消 </a-button>
            </a-form-model-item>
        </a-form>
    </div>
</template>
<script>
import * as api from "@/api/demandPoolShunyi/index.js";
import wangEditor from "wangeditor";
import { IMG_API } from "@/config";
export default {
    data() {
        return {
            add: {
                projectId: [],
                introduceText: '',
                projectName:''
            },
            projectName: [],
            IMG_API: IMG_API,
            info: {},
            editor:''
        }
    },
    async mounted() {
        await this.createEditor()
    },
    methods: {
        handleAddOk(params) {
            let obj = {
                ...this.add
            }
            if (!obj.projectId[obj.projectId.length - 1]) {
                this.$message.error('请选择所属项目')
                return
            }
            obj.projectId = obj.projectId[obj.projectId.length - 1]
            obj.introduceText? obj.introduceText = obj.introduceText.replace(/\{/g, "<span class='phone'>").replace(/\}/g, "</span>"):''
            if (!this.$route.query.id) {
                api.addIntroduce(obj).then((res) => {
                    console.log(res, '==============>');
                    if (res.code === "200") {
                        this.addShow = false;
                        this.$message.success("添加成功");
                        this.goBack()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                api.updateIntroduce({ ...obj, id: this.$route.query.id }).then((res) => {
                    console.log(res, '==============>');
                    if (res.code === "200") {
                        this.addShow = false;
                        this.$message.success("添加成功");
                        this.goBack()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 取消
        goBack() {
            this.$router.go(-1);
        },
        async getEnterpriseProjectList(val) {
            let type = this.$route.query.id ? 2 : 1
            let params = {
                "enterpriseName": val,
                "page": 0,
                "size": 100,
                type
            }
            let res = await api.getEnterpriseProjectList(params)
            this.enterpriseMsg = res.data.enterpriseList
            console.log(res, '==================>');
        },
        //选择企业
        selectEnterPrise(value) {
            this.getProject(value);
        },
        // 获取所属项目
        getProject(id) {
            this.enterpriseMsg.forEach((item) => {
                if (item.enterpriseId === id) {
                    this.EprojectList = item.projectList;
                }
            });
        },

        // 创建富文本
        async createEditor() {
            const _this = this;
            const editor = new wangEditor(`#editer`);
            // 配置 onchange 回调函数，将数据同步到 vue 中
            editor.config.onchange = (newHtml) => {
                this.add.introduceText = newHtml;
            };
            // 隐藏全屏
            editor.config.showFullScreen = false
            editor.config.menus = [
                "fontSize",
                "foreColor",
                "backColor",
                "underline",
                "italic",
                "bold",
                "justify",
                "splitLine",
                "undo",
                "redo",
                "list",
                "table",
                "image",
                // "video",
            ];
            editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
            editor.config.uploadImgServer = this.IMG_API + "/oss/files";
            editor.config.uploadImgShowBase64 = false;
            editor.config.showLinkImg = false;
            editor.config.showLinkVideo = false;
            editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
            editor.config.uploadImgMaxSize = 10 * 1024 * 1024;
            editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
            editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
            // editor.config.uploadVideoAccept = ['mp4']
            editor.config.uploadFileName = "file";
            editor.config.uploadVideoName = "file";
            editor.config.uploadVideoTimeout = 60000;
            editor.config.uploadImgTimeout = 60000;
            editor.config.uploadVideoHooks = {
                // 上传视频之前
                before: function (xhr, editor, files) {
                    console.log(xhr);
                    console.log(editor);
                    console.log(files);
                },
                // 视频上传并返回了结果，视频插入已成功
                success: function (xhr) {
                    // this.$message.success('上传视频成功')
                },
                // 视频上传并返回了结果，但视频插入时出错了
                fail: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频出错，一般为 http 请求的错误
                error: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频超时
                timeout: function (xhr) {
                    _this.$message.error("上传请求超时");
                },
                // 视频上传并返回了结果，想要自己把视频插入到编辑器中
                // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
                customInsert: function (insertVideoFn, result) {
                    let videoHTML =
                        '&nbsp;<video poster="' +
                        result.redirect_uri +
                        '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
                        result.redirect_uri +
                        '" controls style="max-width:100%"></video>&nbsp;';
                    editor.cmd.do("insertHTML", videoHTML);
                    // result 即服务端返回的接口
                    // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                    // insertVideoFn(result.redirect_uri)
                },
            };
            editor.config.uploadImgHooks = {
                // 上传视频之前
                before: function (xhr) { },
                // 视频上传并返回了结果，视频插入已成功
                success: function (xhr) {
                    // this.$message.success('上传视频成功')
                },
                // 视频上传并返回了结果，但视频插入时出错了
                fail: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频出错，一般为 http 请求的错误
                error: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频超时
                timeout: function (xhr) {
                    _this.$message.error("上传请求超时");
                },
                // 视频上传并返回了结果，想要自己把视频插入到编辑器中
                // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
                customInsert: function (insertVideoFn, result) {
                    // result 即服务端返回的接口
                    // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                    insertVideoFn(result.redirect_uri);
                },
            };
            editor.config.customAlert = function (s, t) {
                switch (t) {
                    case "success":
                        _this.$message.success(s);
                        break;
                    case "info":
                        _this.$message.info(s);
                        break;
                    case "warning":
                        _this.$message.warning(s);
                        break;
                    case "error":
                        _this.$message.error(s);
                        break;
                    default:
                        _this.$message.info(s);
                        break;
                }
            };
            editor.config.placeholder = "请输入";
            // 创建编辑器
            editor.create();
            this.editor = editor;
            // 查看的时候禁用富文本
            if (this.$route.query.type === "info") {
                this.editor.disable();
            } else {
                this.editor.enable();
            }
        },
    },
    async created() {
        if (this.$route.query.id) {
            api.IntroduceInfo(this.$route.query.id).then(res => {
                this.add = res.data
                this.editor.txt.html(res.data.introduceText);
                this.add.introduceText = res.data.introduceText
                this.add.projectId = [res.data.projectId]
                this.add.projectName = res.data.projectName
                console.log(res, '=>>>>>>>>>>>>>>>>>>>>>>>>>>>');
            })
        }
        try {
            const res = await api.getShunyiAllProject({ check_type: false });
            if (res.code === "200") {
                this.projectName = res.data;
            } else {
                this.$message.error(res.msg);
            }
        } catch (error) { }

    }
}

</script>
<style lang='scss' scoped>
.header {
    display: flex;
    justify-content: space-between;
}
</style>